import * as React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import FavIcon from '../images/favicon-32x32.png'

const Seo = ({ title = ``, description = ``, pathname = ``, image = ``, children = null }) => {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
            description
            siteUrl
            siteTitle
        }
      }
    }
  `)
  const {
    siteTitle,
    siteUrl,
    description: defaultDescription,
    siteLanguage,
    siteImage: defaultImage
  } = data.site.siteMetadata

  const seo = {
    description: description || defaultDescription,
    url: `${siteUrl}${pathname || ``}`,
    image: `${siteUrl}${image || defaultImage}`,
  }
  return (
    <Helmet title={title} titleTemplate={`%s | ${siteTitle}`}>
      <html lang={siteLanguage} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:type" content="website" />
      <meta property="og:image:alt" content={seo.description} />
      <link rel="icon" type="image/png" sizes="32x32" href={FavIcon} />
      {children}
    </Helmet>
  )
}

export default Seo