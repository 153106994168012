import * as React from "react"
import './css/platform-item.scss'

export function PlatformItem({title, img, description}) {

  return (
    <div className="platform-item">
        <div className="pt-4">
            <img src={img} alt={title} title={title}/>
            <h3 className="platform-item--heading h4 mt-4">{title}</h3>
            <p>{description}</p>
        </div>
    </div>
  )
}
