import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

export function News() {
    const data = useStaticQuery(graphql`
    query NewsQuery {
        allMarkdownRemark(
            filter: {fileAbsolutePath: {regex: "/(news)/"}}
            sort: {fields: [frontmatter___posted], order: DESC}) {
          nodes {
            html
            frontmatter {
              title
              posted
              type
              link
            }
          }
        }
      }
  `)

  return (
    <section className="container px-4 py-5 mt-md-3 my-lg-5 py-5">
      <a id="news" className="jumpto-offset"></a>
      <h2 className="h1 section--header text-center pb-2 pb-md-0 mb-4 mb-md-5">News</h2>
      <div className="row gx-3 gx-md-4 mt-n2 mt-sm-0">
        <div className="col">
        {
            data.allMarkdownRemark.nodes.map(p => {
              return (
                  <article className="card border-0 shadow-sm overflow-hidden mb-4" key={p.title}>
                      <div className="row g-0">
                          <div className="col">
                              <div className="card-body">
                                  <div class="d-flex align-items-center mb-3">
                                      <a href="#" class="badge fs-sm text-nav bg-secondary text-decoration-none">{p.frontmatter.type}</a>
                                      <span class="fs-sm text-muted border-start ps-3 ms-3">{p.frontmatter.posted}</span>
                                  </div>
                                  <h3 className="h4">{p.frontmatter.title}</h3>
                                  <div dangerouslySetInnerHTML={{ __html: p.html }} />
                                  <a href={p.frontmatter.link} target="_blank" rel="noreferrer">Read more</a>
                              </div>
                          </div>
                      </div>
                  </article>
              )
            })
          }
          </div>
      </div>
    </section>
  )
}



