import * as React from "react"
import { PlatformItem } from "./platform-item"
import assay from '../images/platform/assay.png'
import lcms from '../images/platform/lcms.png'
import prism from '../images/platform/prism.png'

const platforms = [
  {
    title: "Genomics",
    img: prism,
    description: "Our genomics platform identifies microbial strains that produce novel chemistry and we can predict the chemotype, structure, drug-likeness, and even target of the resulting compounds"
  },
  {
    title: "Metabolomics",
    img: lcms,
    description: "We use our metabolomics platform to identify novel and known compounds produced by microbes, which are automatically isolated for downstream evaluation, without messing around with cloning or cell-free production"
  },
  {
    title: "Assay Biology",
    img: assay,
    description: "Our platform generates novel chemical libraries amenable to broad phenotypic characterization, high content screening, and focused biochemical assays"
  }
]

export function Platform() {

  return (
    <section className="container px-4 py-5 mt-md-3 my-lg-5 py-5">
      <a id="platform" className="jumpto-offset"></a>
      <h2 className="h1 section--header text-center pb-2 pb-md-0 mb-4 mb-md-5">Platform</h2>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gx-3 gx-md-4 mt-n2 mt-sm-0 justify-content-center">
        {
            platforms.map(p => {
              return (
                <div className="col pb-1 pb-lg-3 mb-4" key={p.title}>
                  <PlatformItem title={p.title} description={p.description} img={p.img}></PlatformItem>
                </div>
              )
            })
          }
      </div>
    </section>
  )
}
