import * as React from "react"
import pfizer from "../images/investors/pfizer.png"
import genesys from "../images/investors/genesys.png"
import gf from "../images/partners/gates_foundation.png"
import irap from "../images/partners/nrc_irap.png"
import agbiome from "../images/partners/agbiome.png"
import lallemand from "../images/partners/lallemand.png"
import gc from "../images/partners/genome_canada.png"


const investors = [
  {
    name: "pfizer",
    image: pfizer
  },
  {
    name: "Genesys",
    image: genesys
  }
]

const partners = [
  {
    name: "AgBiome",
    image: agbiome
  },
  {
    name: "Lallemand",
    image: lallemand
  },
  {
    name: "Genome Canada",
    image: gc
  }
]

const funders = [
  {
    name: "Gates Foundation",
    image: gf
  },
  {
    name: "NRC IRAP",
    image: irap
  }
]

export function Partners({ children }) {
  return (
    <section className="position-relative bg-secondary py-5">
        <a id="partners" className="jumpto-offset"></a>
        <div className="container mt-3 pt-md-2 pt-lg-4 pb-2 pb-md-4 pb-lg-5">
            <h2 className="h1 section--header text-center pb-2 pb-md-0 mb-4 mb-md-5">Selected Partners</h2>
            <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 g-3 justify-content-center">
                {partners.map((i) => {
                  return (
                      <div className="pb-4 text-center">
                        <img className="mx-auto" style={{height: '60px'}} src={i.image} alt={i.name} title={i.name}/>
                      </div>
                  )
                })}
            </div>
        </div>
        <a id="funders" className="jumpto-offset"></a>
        <div className="container mt-3 pt-md-2 pt-lg-4 pb-2 pb-md-4 pb-lg-5">
            <h2 className="h1 section--header text-center pb-2 pb-md-0 mb-2 mb-md-2">Funders</h2>
              <div className="mb-4 row justify-content-center">

                  {funders.map((i) => {
                    return (
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6 text-center">
                        <img src={i.image} alt={i.name} title={i.name}/>
                      </div>
                    )
                  })}

              </div>
        </div>
        <a id="investors" className="jumpto-offset"></a>
        <div className="container mt-3 pt-md-2 pt-lg-4 pb-2 pb-md-4 pb-lg-5">
            <h2 className="h1 section--header text-center pb-2 pb-md-0 mb-2 mb-md-2">Investors</h2>
              <div className="mb-4 row justify-content-center">

                  {investors.map((i) => {
                    return (
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6 text-center">
                        <img src={i.image} alt={i.name} title={i.name}/>
                      </div>
                    )
                  })}

              </div>
        </div>
    </section>
  )
}
