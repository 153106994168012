import * as React from "react"
import {Layout} from "../components/layout.jsx"
import { graphql } from "gatsby"
import Seo from "../components/seo.jsx"
// markup
const PrivacyPage = ({data}) => {
  const { html } = data.allMarkdownRemark.edges[0].node
  return (
    <Layout>
      <Seo title="Privacy"></Seo>
      <section className="position-relative py-5">
        <div className="container mt-3 pt-md-2 pt-lg-4 pb-2 pb-md-4 pb-lg-5">
            <h2 className="h1 section--header text-center pb-2 pb-md-0 mb-4 mb-md-5">Privacy Policy</h2>
          <div
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </section>
    </Layout>
  )
}

export default PrivacyPage

export const query = graphql`
query {
  allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/privacy/"}}
  ) {
      edges {
        node {
          frontmatter {
            title
          }
          html
        }
      }
    }
}
`