import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

export function Leadership() {
    const data = useStaticQuery(graphql`
    query ManagementQuery {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(management)/"}} sort: {fields: [frontmatter___order], order: ASC}) {
          nodes {
            fields {
              slug
            }
            html
            frontmatter {
              credentials
              name
              title
              order
              featuredImage {
                childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 200, quality: 90)
                }
              }
            }
          }
        }
      }

  `)
  return (
    <div className="container mt-3 pt-md-2 pt-lg-4 pb-2 pb-md-4 pb-lg-5">
      <a id="board" className="jumpto-offset"></a>
      <h2 className="h1 section--header text-center pb-2 mt-4 pb-md-0 mb-4 mb-md-5">Board of Directors</h2>
        {data.allMarkdownRemark.nodes.map(t =>(
            <div className="row pb-5 mb-md-4 application-item position-relative" key={t.frontmatter.name}>
              <a style={{position: "absolute", top: "-150px", left: "0px"}} id={t.frontmatter.name.replace(/ /g,'')}></a>
              <div className="col-md-auto pb-1 mb-3 pb-md-0 mb-md-0 pt-2">
                <GatsbyImage
                image={t.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
                className="d-block rounded mx-auto"
                imgClassName="rounded"
                alt={t.frontmatter.name}></GatsbyImage>
              </div>
              <div className="col">
                  <h2 className="h3 text-center text-md-start mb-2 application-item--heading">
                    {t.frontmatter.name}{t.frontmatter.credentials !== null ? ', ' + t.frontmatter.credentials + ' ' : ' '}
                    <span className="fs-sm text-center text-md-start  text-muted">{t.frontmatter.title}</span>
                  </h2>

                  <div className="px-4 px-md-0" dangerouslySetInnerHTML={{ __html: t.html }} />
              </div>
            </div>

            ))}
    </div>
  )
}



