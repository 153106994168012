import * as React from "react"
import './css/hero.scss'
import HeroImg from '../images/hero.png'

export function Hero({ children }) {
    return (
        <section className="position-relative d-flex min-vh-100 bg-light pt-4" style={{"background": 'radial-gradient(116.18% 118% at 50% 100%, rgba(99, 102, 241, 0.1) 0%, rgb(119 70 239 / 5%) 41.83%, rgba(241, 244, 253, 0.07) 82.52%)'}}>
          <div className="container position-relative zindex-5 py-3 px-4">
            <div className="row justify-content-md-start justify-content-center">
              <div className="col-md-4 col-sm-8 d-flex flex-column justify-content-between mt-4 pt-2 text-md-start text-center">
                <div className="mb-md-5 pb-xl-5 mb-4">
                  <h1 className="display-2 mb-md-1 mb-1 pb-3 robot">
                  Making<br/>microbes<br/> work
                  </h1>
                  <p class="fs-lg text-center text-md-start pe-md-5 pe-lg-5 pb-2 pb-md-3 mb-4 mb-lg-5">
                  Adapsyn Bioscience is a chemical bioinformatics company that discovers novel molecules for development as therapeutics, and for use in agriculture, food, and nutrition
                  </p>
                <div className="d-inline-flex align-items-center justify-content-center justify-content-md-start position-relative">
                  <a href="#platform" data-scroll="" data-scroll-offset="100" className="btn btn-video btn-icon rounded-circle shadow-sm flex-shrink-0 stretched-link me-3">
                    <i className="bx bx-chevron-down"></i>
                  </a>
                  <span className="fs-sm robot-light">Discover more</span>
                </div>
              </div>
            </div>
            <div class="col-md-8 ps-lg-2 pe-0">
              <div class="position-relative overflow-hidden mt-4 pb-3 pt-4 mx-auto me-md-0 text-lg-end">
                <img src={HeroImg} title="Adapsyn" alt="Adapsyn"/>
              </div>
            </div>
        </div>
        </div>
      </section>
    )
}
