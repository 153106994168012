import * as React from "react"
import './css/application-item.scss'
import agriculture from '../images/applications/agriculture.png'
import drugDiscovery from '../images/applications/drug_discovery.png'
import foodNutrition from '../images/applications/food_nutrition.png'

const applications = [
    {
        title: "Drug Discovery",
        img: drugDiscovery,
        body: "Adapsyn's proven platform technology can consistently and reliably identify, isolate, and characterize novel, drug-like small molecules produced by microbes. We can create focused screening libraries based on a specific indication or target, and we can screen thousands of novel compounds a year. Using various comprehensive screening modalities, we've identified novel antineoplastic, anti-infective, and immunomodulatory small molecules, and we're advancing the most promising candidates identified to date."
    },
    {
        title: "Agriculture",
        img: agriculture,
        body: "Our platform can be used to identify the compounds produced by a microbe that may be responsible for the beneficial effects observed in phenotypic screening campaigns, and our analysis can help select microbes for evaluation given a specific target, pathogen, or application. We can potentially identify alternate producers of specific compounds of interest, and we can use genomics and metabolomics to find derivatives of known high-value compounds."
    },
    {
        title: "Food and Nutrition",
        img: foodNutrition,
        body: "Because we can identify both novel and known compounds produced by microbes, our platform can help define the underlying mechanisms responsible for beneficial health effects observed with probiotics or live microbial therapeutics. We can also identify novel food ingredients, such as flavour enhancers, bitter blockers, or natural preservatives, produced by microbes."
    }
]

export function Applications() {

  return (
    <section className="container px-4 pb-2 pb-lg-3">
      <a id="applications" className="jumpto-offset"></a>
      <h2 className="h1 section--header text-center pb-2 pb-md-0 mb-4 mb-md-5">Applications</h2>
      {applications.map(a => (
        <div className="row pb-5 mb-md-4 application-item" key={a.title}>
            <div className="col-md-4 pb-1 mb-3 pb-md-0 mb-md-0 text-left px-4">
                <img src={a.img} alt={a.title} style={{maxHeight: '300px'}} />
            </div>
            <div className="col-md-8">
                <h2 className="h3 application-item--heading text-center text-md-start">{a.title}</h2>
                <p className="d-lg-block pb-3 mb-2 mb-md-3 px-4 px-md-0">
                    {a.body}
                </p>
            </div>
        </div>
      ))}

    </section>
  )
}
