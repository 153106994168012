import * as React from "react"
import { Link } from "gatsby"
import "./css/footer.scss"
import Logo from '../images/logo-white.png'

const logoFragment = () => <img src={Logo} width="47" className="me-2" alt="Adapsyn"/>

export function Footer({ children }) {
  return (
  <footer className="footer dark-mode bg-dark border-top border-light pt-5 pb-4 pb-lg-5">
      <div className="container pt-xl-4 px-4">
        <div className="row pb-5">
          <div className="col-lg-4 col-md-6">
            <div className="navbar-brand p-0 me-0 mb-3 mb-lg-4">
              <div href="/" className="company-font">
                {logoFragment()}
                ADAPSYN
              </div>
            </div>
            <p className="fs-sm text-light opacity-70 pb-lg-3 mb-4">&copy; 2022 Adapsyn Bioscience Inc.</p>

          </div>
          <div className="col-xl-6 col-lg-7 col-md-5 offset-xl-2 offset-md-1 pt-4 pt-md-1 pt-lg-0">
            <div id="footer-links" className="row">
              <div className="col-lg-4">
                <div id="useful-links" className="d-lg-block">

                  <ul className="nav flex-column mb-2 mb-lg-0">
                    <li className="nav-item">
                      <Link to="/terms" className="nav-link d-inline-block px-0 pt-1 pb-2">Terms &amp; Conditions</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/privacy" className="nav-link d-inline-block px-0 pt-1 pb-2">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

