import * as React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo.jsx"
import {Layout} from "../components/layout.jsx"
import { GatsbyImage } from "gatsby-plugin-image"

const BioTemplate = ({ data }) => {
  const markdown = data.markdownRemark
  const {frontmatter, html} = markdown
  const {name, title, credentials} = frontmatter
  const image = frontmatter.featuredImage.childImageSharp.gatsbyImageData

  return (
    <Layout>
      <Seo title={frontmatter.name}></Seo>
      <section className="position-relative py-5">
        <div className="container mt-3 pt-md-2 pt-lg-4 pb-2 pb-md-4 pb-lg-5">
            <div className="text-center mb-5">
              <h2 className="h1 section--header pb-2 pb-md-0 mb-0">{name}{credentials !== null ? ', ' + credentials : ''}</h2>
              <p className="fs-sm mb-3">{title}</p>
            </div>
            <div className="row pb-5 mb-md-4 application-item">
                <div className="col-12 col-sm-auto pb-1 mb-3 pb-md-0 mb-md-0">
                  <GatsbyImage image={image} className="d-block rounded-3"></GatsbyImage>
                </div>
                <div className="col-12 col-md-6">
                  <div dangerouslySetInnerHTML={{ __html: html }} />
                </div>
            </div>
          
        </div>
      </section>
    </Layout>
  )
}

export default BioTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
  ) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        name
        credentials,
        featuredImage {
          childImageSharp {
              gatsbyImageData(layout: FIXED, width: 300, quality: 90)
          }
        }
      }
    }
  }
`