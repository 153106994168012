import * as React from "react"

export function Contact({ children }) {
    return (
        <section className="container px-4 pb-5 mb-lg-4 mb-xl-5 py-5">
            <a id="contact" className="jumpto-offset"></a>
            <h2 className="h1 section--header text-center pb-2 pb-md-0 mb-4 mb-md-5">Contact</h2>
            <div className="row py-3">
                <div className="col-md-4 pt-1 mb-4 mb-md-0 robot-light">
                    <ul className="list-unstyled pb-1 pb-lg-4">
                    <li className="mb-3 mb-lg-4">
                        <span className="d-block fs-sm text-muted">Email</span>
                        <a href="mailto:info@adapsyn.com" className="nav-link p-0" style={{fontWeight: "400"}}>info(at)adapsyn.com</a>
                    </li>

                    <li className="mb-3 mb-lg-4">
                        <span className="d-block fs-sm text-muted">Headquarters</span>
                        <div className="">McMaster Innovation Park<br/>175 Longwood Road South, Suite 416A-1<br/>Hamilton, Ontario, Canada<br/>L8P 0A1</div>
                    </li>
                    <li className="mb-3 mb-lg-4">
                        <span className="d-block fs-sm text-muted">US Office</span>
                        <div className="">329 Oyster Point Blvd, 3rd Floor #3500-L<br/>South San Francisco, California<br/>94080</div>
                    </li>
                    <a href="https://www.linkedin.com/company/adapsyn/" target="_blank" rel="noreferrer" className="btn btn-icon btn-outline-secondary btn-linkedin mb-3 me-3">
                        <i className="bx bxl-linkedin"></i>
                    </a>
                    </ul>
                    <div className="d-flex">

                    </div>
                </div>
                <div className="col-xl-7 col-md-8 offset-xl-1">
                    <div className="shadow-sm rounded-3 overflow-hidden">
                    <iframe className="d-block" title="googlemaps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2905.743075667662!2d-79.90283504898622!3d43.256806179034584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882c9b433e06732d%3A0x3e7d2474487bb55b!2s175%20Longwood%20Rd%20S%2C%20Hamilton%2C%20ON%20L8P%200A1!5e0!3m2!1sen!2sca!4v1643891082050!5m2!1sen!2sca" width="600" height="450" style={{border: 0}} loading="lazy"></iframe>
                    </div>
                </div>
            </div>
        </section>
    )
}
