import * as React from "react"
import {Layout} from "../components/layout.jsx"
import { Hero } from "../components/hero.jsx"
import { Platform } from '../components/platform.jsx'
import { Partners } from "../components/partners.jsx"
import { Applications } from "../components/applications.jsx"
import {Contact} from "../components/contact.jsx"
import Seo from "../components/seo.jsx"
import {News} from '../components/news.jsx'

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home"></Seo>
      <Hero></Hero>
      <Platform></Platform>
      <Applications></Applications>
      <Partners></Partners>
      <News></News>
      <Contact></Contact>
    </Layout>
  )
}

export default IndexPage
