import React, {useState, useEffect} from "react"
import { Navbar } from "./navbar"
import "./css/header.scss"
import Logo from '../images/logo.png'

const logoFragment = () => <img src={Logo} width="47" alt="Adapsyn"/>

export const Header = () => {
  const [menu, setMenu] = useState(false)
    // Sticky Menu Area
    useEffect(() => {
      window.addEventListener('scroll', isSticky);
      return () => {
          window.removeEventListener('scroll', isSticky);
      }
  })
  const isSticky = (e) => {
    const header = document.querySelector('.header');
    const scrollTop = window.scrollY;
    scrollTop >= 250 ? header.classList.add('navbar-stuck') : header.classList.remove('navbar-stuck');
}
  return (
    <header className="header navbar navbar-expand-lg navbar-light navbar-sticky">
      <div className="container px-4">
        <a href="/" className="navbar-brand company-font">
          {logoFragment()}
          <span className="navbar-brand-text">ADAPSYN</span>
        </a>
        <div className="spacer"></div>
        <Navbar menu={menu} setMenu={setMenu}></Navbar>
        <a href="/login" className="btn btn-primary btn-sm fs-sm rounded d-none d-lg-inline-flex ms-4">
          Partner Login
        </a>
        <button onClick={() => setMenu(!menu)} type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
  </header>
  )
}


