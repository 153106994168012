import * as React from "react"
import Seo from "../components/seo.jsx"
import '../components/css/login.scss'
// markup
const PartnerLogin = () => {
  return (
    <main className="page-wrapper partner-login">
      <Seo title="Partner Login"></Seo>
      <section className="vh-100" style={{backgroundColor: 'rgb(249 249 249)'}}>
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div className="card shadow-2-strong" style={{borderRadius: '1rem'}}>
                <div className="card-body p-5">

                  <h3 className="mb-5 text-center">Adapsyn Platform</h3>

                  <label className="form-label" for="typeEmailX-2">Email</label>
                  <div className="form-outline mb-4">
                    <input type="email" id="typeEmailX-2" className="form-control form-control-lg" />
                    <div className="form-notch">
                      <div className="form-notch-leading" style={{width: '9px'}}></div>
                      <div className="form-notch-middle" style={{width: '64.8px'}}></div>
                      <div className="form-notch-trailing"></div>
                    </div>
                  </div>

                  <label className="form-label" for="typePasswordX-2">Password</label>
                  <div className="form-outline mb-4">
                    <input type="password" id="typePasswordX-2" className="form-control form-control-lg" />
                    <div className="form-notch">
                      <div className="form-notch-leading" style={{width: '9px'}}></div>
                      <div className="form-notch-middle" style={{width: '64.8px'}}></div>
                      <div className="form-notch-trailing"></div>
                    </div>
                  </div>

                  <button className="btn btn-primary btn-lg btn-block" type="submit">Login</button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default PartnerLogin
