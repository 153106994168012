import * as React from "react"
import {Header} from "./header"
import { Footer } from "./footer"
import "./css/layout.scss"

export function Layout({ children }) {
  return (
    <>
        <main className="page-wrapper">
          <Header></Header>
          {children}
        </main>
        <Footer></Footer>
    </>
  )
}